<template>
  <a-card :bordered="false">
    <div>
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="状态记录"
        @back="() => $router.go(-1)"
      >
        <a-row type="flex">
        </a-row>
      </a-page-header>
      <s-table
        ref="table"
        size="middle"
        rowKey="id"
        :show-pagination="false"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, records, index">
          {{ index + 1 }}
        </span>
      </s-table>
    </div>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import { e_ceb607_order_status_list } from '@/api/ceb607'

export default {
  name: 'OrderReadList',
  components: {
    STable
  },
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      expandedRowKeys: ['id'],
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 100,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单号',
          dataIndex: 'bill_no'
        },
        {
          title: '电子口岸编号',
          dataIndex: 'pre_no'
        },
        {
          title: '企业唯一编号',
          dataIndex: 'cop_no'
        },
        {
          title: '物流企业代码',
          width: 200,
          dataIndex: 'logistics_code'
        },
        {
          title: '返回信息',
          dataIndex: 'return_info'
        },
        {
          title: '电商企业代码',
          dataIndex: 'ebc_code'
        },
        {
          title: '返回状态',
          dataIndex: 'return_status'
        },
        {
          title: '创建时间',
          width: 200,
          dataIndex: 'create_time',
          customRender: (text) => text === '1970-01-01' ? '无' : text
        },
        {
          title: '返回时间',
          width: 200,
          dataIndex: 'return_time',
          customRender: (text) => text === '1970-01-01' ? '无' : text
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', this.$route.params.cop_no)
        return e_ceb607_order_status_list({}, this.$route.params.cop_no)
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return { entries: res.data }
          })
      }
    }
  },
  created () {},
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    }
  }
}
</script>
